<template>
  <footer class="footer container">
    <div class="ui vertical menu">
      <a class="item footer authot logo" href="/"></a>
      <p>Copyright 2023</p>
      <a href="https://www.authot.com" target="_blank" class="routerLink">www.authot.com</a>
      <p>+33 1 58 46 86 88</p>
      <p><a href="mailto:contact@authot.com" class="orange text" target="_blank" ></a></p>
    </div>
    <div class="ui vertical menu">
      <!-- <p><a v-bind:href="t('layout.footer.cgu_link')" target="_blank">{{ t('layout.footer.cgu') }}</a></p>  -->
      <!-- <p><a href="https://app.xn--autht-9ta.com/Authot_CGV_fr.pdf" target="_blank">{{ t('layout.footer.cgv') }}</a></p> -->
      <p><a href="https://authot.app/legal" target="_blank">Mentions Legales</a></p>
      <p><a href="https://authot.app/tickets/new" target="_blank">Contactez-nous</a></p>
      <p style="color:#FAFCFE"> V3.50.0 23/01</p>
    </div>
  </footer>
  <!-- <footer>
    <router-link to="/" class="routerLink">Copyright</router-link>
    <router-link to="/contact" class="routerLink">Contactez-nous !</router-link>
    <router-link to="/" class="routerLink">Accueil</router-link>
  </footer> -->
  
</template>
<script>

</script>