import AxiosClient from "../axios-config";

export default {
  loginUser(data){
    return AxiosClient.post("/users/sign_in", data)
  },
  logoutUser(data){
    return AxiosClient.delete("/users/sign_out", data)
  },
  loginWithToken(data){
    return AxiosClient.get("/users/sign_in", data)
  }
}