import axios from "axios";
let axiosClient;

export default axiosClient = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    Authorization: localStorage.getItem('auth_token'),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  } 
})
