import { createRouter, createWebHistory } from "vue-router";
import VSessionManager from '../views/VSessionManager.vue';
import VDashboard from "../views/VDashboard.vue"
import VContact from "../views/VContact.vue"


const routes = [
  {
    path: "/",
    name: "VDashboard",
    component: VDashboard,
  },
  {
    path: "/login",
    name: "VSessionManager",
    component: VSessionManager,
  },
  {
    path: "/contact",
    name: "VContact",
    component: VContact,
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

export default router