import AxiosDirectory from '../../services/directory/directory';
import router from '../../router';

const directoriesStore = {
  namespaced: true,
  state: () => ({
    directory: [],
    subDirectory: [],
    document: [],
    newDocuments: [],
    directories: [],
    directoryTotalPage: null,
    documentTotalPage: null,
    downloadAnswer: null,
    simulTrue : 0
  }),
  getters: {
    getDirectory(state) {
      return state.directory;
    },
    getSubDirectory(state) {
      return state.subDirectory;
    },
    getDocuments(state) {
      return state.document;
    },
    getDirectories(state) {
      return state.directories;
    },
    getTotalDirectoryPage(state) {
      return state.directoryTotalPage;
    },
    getTotalDocumentPage(state) {
      return state.documentTotalPage;
    },
    getDownloadAnswer(state) {
      return state.downloadAnswer;
    }
  },
  mutations: {
    SET_DIRECTORY(state, directory) {
      state.directory = directory;
    },
    SET_SUBDIRECTORY(state, subDirectory) {
      // subDirectory.forEach((item) => {
      //   if(item.attributes.name.length > 20) {
      //     item.attributes.name = item.attributes.name.substr(0, 20) + '...' + item.attributes.name.substr(item.attributes.name.length-3, item.attributes.name.length);
      //   } else if(item.attributes.name.length > 20 && item.attributes.name.length < 24) {
      //     item.attributes.name = item.attributes.name.substr(0, 17) + '...' + item.attributes.name.substr(item.attributes.name.length-3, item.attributes.name.length);
      //   }
      // })
      state.subDirectory = subDirectory;
    },
    SET_DOCUMENTS(state, documents) {
      // documents.forEach((item) => {
      //   if(item.attributes.name == null) {
      //     item.attributes.name = 'Sans nom';          
      //   } else if(item.attributes.name.length > 20){
      //     item.attributes.name = item.attributes.name.substr(0, 20) + '...' + item.attributes.name.substr(item.attributes.name.length-3, item.attributes.name.length);
      //   } else if(item.attributes.name.length > 20 && item.attributes.name.length < 24) {
      //     item.attributes.name = item.attributes.name.substr(0, 17) + '...' + item.attributes.name.substr(item.attributes.name.length-3, item.attributes.name.length);
      //   }
      // })
      state.document = documents;
      //documents.map(document => state.document.push(document));
    },
    ADD_DIRECTORY(state, data) {
      let newDirectory = {
        id: data.id,
        type: 'directory',
        attributes: {
          name: data.name,
          path: data.path,
        }
      }
      state.directories.push(newDirectory);
    },
    DELETE_DIRECTORY(state, directoryId) {
      state.subDirectory = state.subDirectory.filter((directory) => directory.id != directoryId);
    },
    ADD_DOCUMENT(state, newDocument) {
      state.document.push(newDocument);
    },
    DELETE_DOCUMENT(state, documentId) {
      state.document = state.document.filter(document => document.id != documentId);
    },
    SET_DIRECTORIES(state, directories) {
      directories[0].attributes.name = 'Racine';
      directories.sort(function (a,b) {
        if(a.attributes.name < b.attributes.name) { return -1; }
        if(a.attributes.name > b.attributes.name) { return 1; }
        return 0;
      })
      state.directories = directories;
    },
    SET_DIRECTORY_TOTAL_PAGE(state, totalPage) {
      state.directoryTotalPage = totalPage;
    },
    SET_DOCUMENT_TOTAL_PAGE(state, totalPage) {
      state.documentTotalPage = totalPage;
    },
    SET_DOWNLOAD_STATE(state, answer) {
      state.downloadAnswer = answer;
    },
    RESET_DOWNLOAD(state) {
      state.downloadAnswer = null;
    }
  },
  actions: {
    // getDirectories({ commit }, id) {
    //   AxiosDirectory.getDirectory(id)
    //     .then((response) => {
    //       commit('SET_DIRECTORY', response.data.data);
    //       commit('SET_SUBDIRECTORY', response.data.data.attributes.directories.data);
    //       commit('SET_DOCUMENTS', response.data.data.attributes.documents.data);
    //     }).catch(error => {
    //       console.log(error);
    //     });
    // },
    getDirectories({ commit }, payload) {
      AxiosDirectory.getDirectory(payload.id, {directory_page: payload.directory_page , document_page: payload.document_page})
        .then((response) => {
          commit('SET_DIRECTORY', response.data.data);
          commit('SET_SUBDIRECTORY', response.data.data.attributes.directories.data);
          commit('SET_DOCUMENTS', response.data.data.attributes.documents.data);
          commit('SET_DIRECTORY_TOTAL_PAGE', response.data.meta.directory_page.total_page);
          commit('SET_DOCUMENT_TOTAL_PAGE', response.data.meta.document_page.total_page);
        }).catch(error => {
          console.log(error);
          if(error.response.status == 401) {
            localStorage.removeItem("auth_token");
            localStorage.removeItem("root_directory_id");
            localStorage.removeItem("directory_id");
            localStorage.removeItem("user_id");
            router.push('/login');
          }
        });
    },
    getAllDirectories({ commit }, id){
      AxiosDirectory.getDirectories(id)
        .then((response) => {
          commit('SET_DIRECTORIES', response.data.data);
        }).catch(error => {
          console.log(error);
        });
    },
    postDirectory({ commit }, data) {     
      AxiosDirectory.postDirectory(data)
        .then((response) => {
          console.log(response);
        }).catch(error => {
          console.log(error);
        });
      commit('ADD_DIRECTORY', data);
    },
    deleteDirectory({ commit }, id) {
      return AxiosDirectory.deleteDirectory(id)
        .then((response) => {
          console.log(response);          
        }).catch(error => {
          console.log(error);
        });        
    },
    postDocument({ commit }, data) {
      console.log(data);
      AxiosDirectory.postDocument(data)
        .then((response) => {
          console.log(response);
          commit('ADD_DOCUMENT', response.data.data);
        })
        .catch(error => {
          console.log(error);
        });
    },
    updateDirectory({ commit }, data) {
      AxiosDirectory.updateDirectory(data.id, {directory: {directory_id: data.directory_id}})
        .then((response) => {
          console.log(response);
        }).catch(error => {
          console.log(error);
        });
        commit('DELETE_DIRECTORY', data.id);
    },
    updateDocument({ commit }, data) {
      AxiosDirectory.updateDocument(data.document_id, {document: { directory_id: data.directory_id}})
        .then((response) => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
        commit('DELETE_DOCUMENT', data.document_id);
    },
    deleteDocument({ commit }, documentId) {
      AxiosDirectory.deleteDocument(documentId)
        .then((response) => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    },
    activeDownload({ state, commit, dispatch }, documentId) {
      AxiosDirectory.downloadDocument(documentId)
        .then((response) => {
          console.log(response);
          if(response.data.success == false) {
            setTimeout(() => {
              dispatch('activeDownload', documentId)
            }, 5000);
          } else {
            let FILE = response.data.url;
            let docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', response.data.url);
            document.body.appendChild(docUrl);
            docUrl.click();
            commit('SET_DOWNLOAD_STATE', response.data.success);
          }
        })
        .catch(error => {
          console.log(error);
      })
    } 
  }
}

export default directoriesStore;