<template>
  <div>
    <CHeader/>
    <CSessionManager/>
    <CFooter/>
  </div>
</template>

<script>
  import CHeader from "../components/header/CHeader.vue"
  import CSessionManager from '../components/auth/CSessionManager.vue'
  import CFooter from '../components/footer/CFooter.vue'
  
  export default {
    name: "VSessionManager",
    computed: {
    },
    data() {
      return {
      }
    },
    methods: {
    },
    components: {
      CSessionManager,
      CHeader,
      CFooter
    }
}
</script>
