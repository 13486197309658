<template>
  <router-view/>
</template>

<script>
// import SessionManager from "./components/auth/CSessionManager.vue";

  export default {
    name: "App",
    components: {
      // SessionManager,
    },
  };
</script>

<style lang="scss">
  @import "./assets/stylesheet/main.scss";

</style>