<template>
  <div class="toast">
    <span>{{message}}</span>
    <i class="times circle outline icon big red"></i>
  </div>
</template>
<script>
export default {
  name: "Toast",
  props: {
    message: {
      type: String,
    },
  },
};
</script>
<style></style>