<template>
  <div class="container-dashboard">
    <div class="navbar">
      <h1>Dashboard</h1>
    </div>

    <div v-if="uploaderVisible" class="container-dashboard-uploader modalContainer">
      <div class="modalBg">
        <CUploader @closeUploader="displayModal('uploader')"></CUploader>
      </div>
    </div>

    <div v-if="moveFileDropdownVisible" class="dashboard moveFile-dropdown modalContainer" >
      <div class="modalBg">
        <div class="modal">
          <h4 class="header">Déplacer le fichier : {{ managedFile }}</h4>
          <span class='closeMoveFile' @click='moveFileDropdownVisible = false'><i class="times icon large"/></span>
        </div>
        <span class="deleteModal_fileName">{{ document_name }}</span>
        <div class="dropdown">
          <button @click="myFunction()" class="dropbtn">{{selectChosen}}</button>
          <div id="myDropdown" class="dropdown-content">
            <input type="text" placeholder="Search.." id="myInput" @keyup="filterFunction()"/>
            <span v-for="item in allDirectories" :key="item.id" @click="directoryChosen(item.id, item.attributes.path)" class="listItem">              
              <p>{{item.attributes.name}}</p>
              <p>{{item.attributes.path}}</p>
            </span>
          </div>
        </div>
        <!-- <select id="selectFolder" v-model="moveFileFolder">
          <option v-for="item in allDirectories" :key="item.id" :value="item.id">{{item.attributes.name}}</option>
        </select> -->
        <button @click="moveFile()" class="ui button">Déplacer</button>
      </div>      
    </div>

    <div v-if="deleteModal" class="modalContainer">
      <div class="modalBg deleteModal">
        <div class="deleteModal_info">
          <h4 class="header">voulez-vous supprimer ce fichier ?</h4>
          <span class="deleteModal_fileName">{{ document_name }}</span>
        </div>
        <div class="deleteModal_answer">          
          <button @click="deleteFile()" class="ui button">OUI</button>
          <button @click="displayModal('deleteFile')" class="ui button deny">NON</button>
        </div>        
      </div>
    </div> 

    <div v-if="addFolderModal" class="dashboard addFolder modalContainer">
      <div class="modalBg">
        <div class="modal">
          <h4 class="header">Ajouter un dossier</h4>
          <span class='closeMoveFile' @click="displayModal('addFolder')">
            <i class="times icon large"/>
          </span>
        </div>
        <input type="text" name="addFolder" id="addFolder" class="addFolder-input"/>
        <button @click="addDirectory()" class="ui button">Ajouter</button>
      </div>      
    </div>

    <div v-if="downloadModal" class="modalContainer">
      <div class="modalBg downloadModal">
        <div class="downloadModal_info">
          <h4 class="downloadModal_header">Fichier en préparation, veuillez patienter ...</h4>
          <span class="downloadModal_fileName">{{ document_name }}</span>          
        </div>      
      </div>
    </div>
    
    <!-- <div class="arbo">
      <ul>
        <li v-for="item in allDirectories" :key="item.id">
          {{item.attributes.name}}
        </li>
      </ul>
    </div> -->

    <div class="container-dashboard-ftp">
      <div class="dashboard_actions">
        <div class="container-path">
          <span> > </span>
          <i class="icon home" @click="pathHome()"></i>
          <div v-for="item in path" :key="item.id" class="path">            
            <span> > </span>
            <span @click="accessFolder(item.id)">{{ item.path }}</span>          
          </div>          
        </div>
        <div class="batchActionButton">
          <div class="buttonGroupe1">
            <button class="ui button" @click="allCheckbox">Tout sélectionner</button>
            <button class="ui button" @click="openBatchActionDelete">Supprimer sélection</button>
            <button class="ui button" @click="openBatchActionMoveFile">Déplacer sélection</button></div>
          <div class="buttonGroupe2">
            <button class="ui button" @click="displayModal('uploader')">+ Télécharger un fichier</button>
            <button class="ui button" @click="displayModal('addFolder')">Ajouter un dossier</button>    
          </div>          
        </div>
      </div>      
      
      <div class="ui list ftp-list">
        <div class="mainList directoryList">
          <div class="ftp-directories" v-for="item in subDirectories" :key="item.id" >
            <div class="decoration-directories">            
              <input type="checkbox" class="batchAction directory" :value="item.id"/>
              <i class="folder icon large"></i>
            </div>
            <div class="container_list_title" @click="accessFolder(item.id, item.attributes.name)">
              <h2 class="item-folders list-title" >{{item.attributes.name}}</h2>
            </div>            
          </div>
          <UTablePagination
            v-if="subDirectories.length > 0"
            :total="totalDirectoryPage"
            :pageChanged="1"
            :perPageChanged="numberOfItemDirectory"
            typeOfItem="directory"
            dropdownId="style-pagination"
            @emitRedrawList="redrawList" 
            @getNextPage="getNextPage"
            @getPreviousPage="getPreviousPage"
          ></UTablePagination>
        </div>
        
        <div class="mainList documentList">
          <div class="ftp-files" v-for="item in documents" :key="item.id">   
            <div class="decoration-files">
              <input type="checkbox" class="batchAction document" :value="item.id"/>
              <h2 class="item-files list-title">{{item.attributes.name}}</h2>
            </div>      
            <div class="item-actions">
              <div @click="downloadFile(item.id, item.attributes.name)"><i class="file download icon large"></i></div>
              <div @click='openMoveFile(item.id, item.attributes.name)'><i class="file export icon large"/></div>
              <div @click='openDeleteFile(item.id, item.attributes.name)'><i class="trash alternate icon large"/></div>
            </div>
          </div>
          <UTablePagination
            v-if="documents.length > 0" 
            :total="totalDocumentPage"
            :page-changed="1"
            :per-page-changed="numberOfItemDocument"
            typeOfItem="document"
            dropdownId="style-pagination"
            @emitRedrawList="redrawList" 
            @getNextPage="getNextPage"
          ></UTablePagination>
        </div>
        
      </div>     
    </div>

    <!-- <Transition>
      <Toast v-if="displayToast" :message="message"></Toast> 
    </Transition>  -->
  </div>
</template>

<script>
  import "@/store/index.js";
  import { mapGetters, mapActions } from "vuex";
  import { displayToast, truncateKey } from '../../utils/functions'; 
  //import Toast from "../utils/UToast.vue";
  import UTablePagination from "../utils/UTablePagination.vue";

  import CUploader from "../uploader/CUploader.vue"

  export default {
    data(){
      return {
        uploaderVisible: false,
        moveFileDropdownVisible: false,
        addFolderModal: false,
        deleteModal: false,
        downloadModal: false,
        managedFile: null,
        moveFileFolder: null,
        path: [],
        currentDirectoryId: null,
        directory_id: null,
        document_id: null,
        document_name: null,
        allSelectedDocument: [],
        allSelectedDirectory: [],
        allChecked: false,
        selectChosen: "Choisir un dossier",
        numberOfItemDirectory: 5,
        numberOfItemDocument: 5,
        currentPageDirectory: 1,
        currentPageDocument: 1,
        message: "",
      }
    },  
    computed: {
      documents(){
        return this.$store.getters["directoriesManager/getDocuments"];
      }, 
      directories(){
        return this.$store.getters["directoriesManager/getDirectory"];
      },
      subDirectories(){
        return this.$store.getters["directoriesManager/getSubDirectory"];
      },
      getAuthToken(){
        if(this.$store.getters["sessionManager/getAuthToken"] == null) {
          return localStorage.getItem("authToken");
        } else {
          return this.$store.getters["sessionManager/getAuthToken"];
        }
      },  
      allDirectories(){
        return this.$store.getters["directoriesManager/getDirectories"];
      },
      totalDirectoryPage(){
        return this.$store.getters["directoriesManager/getTotalDirectoryPage"];
      },
      totalDocumentPage(){
        return this.$store.getters["directoriesManager/getTotalDocumentPage"];
      },
      getDownloadAnswer(){
        return this.$store.getters["directoriesManager/getDownloadAnswer"];
      },
    },
    methods: {
      myFunction() {
        document.getElementById("myDropdown").classList.toggle("show");
        this.$store.dispatch("directoriesManager/getAllDirectories", localStorage.getItem("user_id"))
      },

      filterFunction() {
        let input = document.getElementById("myInput");
        let filter = input.value.toUpperCase();
        let div = document.getElementById("myDropdown");
        let a = div.getElementsByTagName("span");
        for (let i = 0; i < a.length; i++) {
          let txtValue = a[i].textContent || a[i].innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = "";
          } else {
            a[i].style.display = "none";
          }
        }
      }, 

      directoryChosen(id, name){
        this.moveFileFolder = id
        this.selectChosen = "Votre fichier ira dans le dossier " + name
        document.getElementById("myDropdown").classList.toggle("show");
      },  
      ...mapActions(["logoutUser"]),
      
      /* ACCESS DIRECTORY*/
      accessFolder(id, name){   
        if(!this.path.some(item => item.id === id)){ 
          this.path.push({id:id, path:name})  
        } else {
          let index = this.path.findIndex(item => item.id === id)
          this.path.splice(index+1, this.path.length)
        }
        this.directory_id = id
        localStorage.setItem("directory_id", id)
        let directory_page = {
          number_page: Number(this.currentPageDirectory),
          per_page: Number(this.numberOfItemDirectory)
        }
        let document_page = {
          number_page: Number(this.currentPageDocument),
          per_page: Number(this.numberOfItemDocument)
        }
        this.$store.dispatch("directoriesManager/getDirectories", {
          id: id,
          directory_page: directory_page,
          document_page: document_page
        })
      },  
      pathHome(){
        this.path = []
        this.directory_id = null
        localStorage.setItem("directory_id", localStorage.getItem("root_directory_id"))
        let directory_page = {
          number_page: Number(this.currentPageDirectory),
          per_page: Number(this.numberOfItemDirectory)
        }
        let document_page = {
          number_page: Number(this.currentPageDocument),
          per_page: Number(this.numberOfItemDocument)
        }
        this.$store.dispatch("directoriesManager/getDirectories", {
          id: null,
          directory_page: directory_page,
          document_page: document_page
        })
        //this.$store.dispatch("directoriesManager/getDirectories", null)
      },  

      /* GESTION DES MODALS*/
      displayModal(modalName){
        if(modalName === "uploader"){
          this.uploaderVisible = !this.uploaderVisible
          this.moveFileDropdownVisible = false
          this.addFolderModal = false
          this.deleteModal = false
          this.downloadModal = false
        } else if(modalName === "addFolder"){
          this.uploaderVisible = false
          this.moveFileDropdownVisible = false
          this.addFolderModal = !this.addFolderModal
          this.deleteModal = false
          this.downloadModal = false
        } else if(modalName === "moveFile"){
          this.uploaderVisible = false
          this.moveFileDropdownVisible = !this.moveFileDropdownVisible
          this.addFolderModal = false
          this.deleteModal = false
          this.downloadModal = false
        } else if(modalName === "deleteFile"){
          this.uploaderVisible = false
          this.moveFileDropdownVisible = false
          this.addFolderModal = false
          this.deleteModal = !this.deleteModal
          this.downloadModal = false
        } else if(modalName === "downloadModal"){
          this.uploaderVisible = false
          this.moveFileDropdownVisible = false
          this.addFolderModal = false
          this.deleteModal = false
          this.downloadModal = !this.downloadModal
        } else {
          this.uploaderVisible = false
          this.moveFileDropdownVisible = false
          this.addFolderModal = false
          this.deleteModal = false
          this.downloadModal = false
        }
      },

      /* ADD DIRECTORY */
      addDirectory(){
        let addFolderName = document.getElementById('addFolder').value
        this.currentDirectoryId = localStorage.getItem("directory_id")
        this.$store.dispatch("directoriesManager/postDirectory", {
          directory_id: this.currentDirectoryId,
          name: addFolderName          
        })
        this.$store.dispatch("directoriesManager/getDirectories", localStorage.getItem("root_directory_id"))
        this.displayModal('addFolder')
        this.accessFolder(this.currentDirectoryId)
      },

      /* MOVE FILE */
      openMoveFile(id, name) {
        this.allSelectedDocument.push(id)
        this.document_name = name
        this.displayModal("moveFile");
      },
      moveFile(){          
        if(this.allSelectedDocument.length > 0){
          this.allSelectedDocument.forEach(id => {
          this.$store.dispatch("directoriesManager/updateDocument", {
            document_id: id,
            directory_id: this.moveFileFolder
          })
        })
        this.allSelectedDocument = []
        } else if(this.allSelectedDirectory.length > 0){
          this.allSelectedDirectory.forEach(id => {
          this.$store.dispatch("directoriesManager/updateDirectory", {
            id: id,
            directory_id: this.moveFileFolder
          })
          })
          this.allSelectedDirectory = []
        }else {
            this.$store.dispatch("directoriesManager/updateDocument", {
            document_id: this.document_id,
            directory_id: this.moveFileFolder
          })  
        }  
        this.displayModal("moveFile");    
      },

      /* DELETE FILE */
      openDeleteFile(id, name) {
        this.document_id = id
        this.document_name = name
        this.displayModal("deleteFile");
      },
      async deleteFile() {        
        if(this.allSelectedDocument.length > 0){
          this.allSelectedDocument.forEach(id => {
            console.log(id)
            this.$store.dispatch("directoriesManager/deleteDocument", id)  
            this.$store.commit("directoriesManager/DELETE_DOCUMENT", id)           
          })          
          this.allSelectedDocument = []
        }
        if(this.allSelectedDirectory.length > 0){
          this.allSelectedDirectory.forEach(id => {
            this.$store.dispatch("directoriesManager/deleteDirectory", id)  
            this.$store.commit("directoriesManager/DELETE_DIRECTORY", id)            
          })
          this.allSelectedDirectory = []
        }
        if(this.document_id){
          await this.$store.dispatch("directoriesManager/deleteDocument", this.document_id)
          this.$store.commit("directoriesManager/DELETE_DOCUMENT", this.document_id)   
          this.document_id = null          
        }
        this.displayModal("deleteFile");      
      },

      /* DOWNLOAD FILE */
      downloadFile(id, name) {
        this.document_name = name
        this.displayModal("downloadModal");
        this.$store.dispatch("directoriesManager/activeDownload", id)        
      },

      /* BATCH ACTIONS */
      allCheckbox(){
        if(!this.allChecked){
          let checkboxes = document.querySelectorAll('.batchAction')
          checkboxes.forEach(checkbox => {
          checkbox.checked = true
          this.allChecked = true
        })}else{
          let checkboxes = document.querySelectorAll('.batchAction')
          checkboxes.forEach(checkbox => {
          checkbox.checked = false
          this.allChecked = false
        })}      
      },
      allUncheckbox(){
        let checkboxes = document.querySelectorAll('.batchAction')
        checkboxes.forEach(checkbox => {
          checkbox.checked = false
        })      
      },
      openBatchActionDelete(){
        this.allSelectedDocument = []
        this.allSelectedDirectory = []
        let checkboxes = document.querySelectorAll('.batchAction')
        checkboxes.forEach(checkbox => {
          if(checkbox.checked && checkbox.classList.contains("document")){
            this.allSelectedDocument.push(checkbox.value)
          } else if(checkbox.checked && checkbox.classList.contains("directory")){
            this.allSelectedDirectory.push(checkbox.value)
          }
        })
        this.document_name = this.allSelectedDocument.length + " documents"
        this.displayModal("deleteFile");
      },
      openBatchActionMoveFile(){
        this.allSelectedDocument = []
        this.allSelectedDirectory = []
        let checkboxes = document.querySelectorAll('.batchAction')        
        checkboxes.forEach(checkbox => {
          if(checkbox.checked && checkbox.classList.contains("document")){
            this.allSelectedDocument.push(checkbox.value)
          } else if(checkbox.checked && checkbox.classList.contains("directory")){
            this.allSelectedDirectory.push(checkbox.value)
          }
        })
        this.document_name = this.allSelectedDocument.length + " documents"
        this.displayModal("moveFile");
      },  

      /* Pagination */
      getPreviousPage(data){
        if(data.type === "directory"){
          if(this.currentPageDirectory > 1){
            this.redrawList(data)
          } else {
            this.showToast("Vous êtes déjà sur la première page")
          }
        }else if(data.type === "document"){
          if(this.currentPageDocument > 1){
            this.redrawList(data)
          } else {
            this.showToast("Vous êtes déjà sur la première page")
          }
        }
      },
      getNextPage(data){
        if(data.type === "directory"){
          this.redrawList(data)
        }else if(data.type === "document"){
          this.redrawList(data)
        }
      },
      redrawList(data){
        if(data.type === "directory"){
          this.numberOfItemDirectory = data.perPage
          this.currentPageDirectory = data.current
        } else if(data.type === "document"){
          this.numberOfItemDocument = data.perPage
          this.currentPageDocument = data.current
        }
        let document_page = {
          number_page: Number(this.currentPageDocument),
          per_page: Number(this.numberOfItemDocument)
        }
        let directory_page = {
          number_page: Number(this.currentPageDirectory),
          per_page: Number(this.numberOfItemDirectory)
        }
        this.$store.dispatch("directoriesManager/getDirectories", {
          id: localStorage.getItem("directory_id"),
          directory_page: directory_page,
          document_page: document_page
        })
      },
      showToast(message) {
        this.displayToast = true;
        this.message = message;
        setTimeout(() => {
          this.displayToast = false;
        }, 5000);
      },
    }, 
    watch:{
      getDownloadAnswer(){
        if(this.getDownloadAnswer == true){
          this.displayModal();
          this.$store.commit("directoriesManager/RESET_DOWNLOAD")
        }        
      }
    },
    mounted() {
      //this.$store.dispatch("sessionManager/loginUserWithToken", localStorage.getItem("auth_token"))
      if(localStorage.getItem("auth_token") === null){
        console.log("pas de token")
        this.$router.push("/login")
      } else {
        this.currentDirectoryId = localStorage.getItem("directory_id")
      }
      this.$store.dispatch("directoriesManager/getDirectories", localStorage.getItem("root_directory_id")) 
      this.$store.dispatch("directoriesManager/getAllDirectories", localStorage.getItem("user_id"))
      localStorage.setItem("directory_id", localStorage.getItem("root_directory_id")) 
      
      let customCSS = import("@/assets/stylesheet/customStyle/user" + localStorage.getItem("user_id") + ".scss");      
    },
    components: {
      CUploader,
      UTablePagination
    }
  }
</script>

<style scoped>
</style>