<template>
  <div class="container-uploader">
    <div>
      <h4 class="header">Uploader de fichier</h4>
      <span class='closeMoveFile' @click='uploaderClose'><i class="times icon large"/></span>
    </div>
    <div class="container-uploader-filezone" id="uploader-drop-zone">
      <p>
        📎
        <span class="button-addfiles" id="uploader-browse-zone">Ajouter</span>
        ou téléverser des fichiers ici
      </p>
    </div>
    <div class="container-uploader-fileslist" id="uploader-drop-zone">
      <div v-for="(file, fileIndex) in files" :key="file.uniqueIdentifier" class="container-uploader-fileslist-file">
        <p>{{ file.fileName }}</p>
        <div class="container-uploader-fileslist-file-infos">
          <span class="container-uploader-fileslist-file-infos-progress">
            <span class="container-uploader-fileslist-file-infos-progress-done" :id="`progress-done-${file.uniqueIdentifier}`"></span>
          </span>
          <span class="container-uploader-fileslist-file-infos-delete" @click="removeFile(file.uniqueIdentifier, fileIndex)">❌</span>
        </div>
      </div>
    </div>
    <button class="ui button" @click="uploadFiles()">Démarrer</button>
  </div>
</template>

<script>
  import Resumable from 'resumablejs';

  export default {
    data() {
      return {
        r: null,
        files: []
      }
    },
    methods: {
      addFile(file) { this.files.push({ fileName: file.fileName, uniqueIdentifier: file.uniqueIdentifier }) },
      removeFile(fileUniqueId, fileIndex) {
        const fileToRemove = this.r.getFromUniqueIdentifier(fileUniqueId);
        this.r.removeFile(fileToRemove);
        this.files.splice(fileIndex, 1);
      },
      uploadFiles() {
        console.log(this.r.files)
        //this.$store.dispatch('folderManager/addFiles', this.r.files);
        this.r.upload();
      },
      uploaderClose(){
        this.$emit('closeUploader');
      }
    },
    mounted() {
      // RESUMABLE INIT
      this.r = new Resumable({
        testChunks: true,
        // fileType: this.fileAccepted,
        target: "https://api-premium.authot.com/upload",
        // target: "http://localhost:3000/upload",
        simultaneousUploads: 1,
        chunkSize: 10 * 1024 * 1024,
        headers: { 
          Authorization: localStorage.getItem('auth_token'),
        },
      });
      this.r.assignBrowse(document.getElementById('uploader-browse-zone'));
      this.r.assignDrop(document.getElementById('uploader-drop-zone'));

      // RESUMABLES EVENTS
      this.r.on('fileAdded', (file) => this.addFile(file));
      this.r.on('fileProgress', (file) => document.getElementById(`progress-done-${file.uniqueIdentifier}`).style.width = `${(file.progress()*100)}%`);
      this.r.on("fileSuccess", (file, message) => {
        const response = JSON.parse(message)
        this.$store.dispatch('directoriesManager/postDocument', {document: {file_path: response.file_path, directory_id: localStorage.getItem('directory_id')}}) 
        //this.uploaderClose()
      });    
      this.r.on('complete', () => this.uploaderClose());  
    },
  }
</script>