<template>
  <div>
    <CHeader/>
    <CDashboard/>
  </div>
  
</template>

<script>
import CDashboard from '../components/dashboard/CDashboard.vue'
import CHeader from '../components/header/CHeader.vue'

export default {
  components: {
    CDashboard,
    CHeader,
  }
}
</script>
