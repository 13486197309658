<template>
  <header>
    <div>
      <div class="imgContainer">
      </div>
      <h1 v-if="customCSS"></h1>
      <a v-if="logOutButton" href="https://authot.studio/ticket/new" class="contact-header" target="_blank">Contactez-nous</a>
    </div>
    <button v-if="logOutButton" @click="logOut()" id="button" class="ui button"><i class="icon sign out alternate"></i>Déconnexion</button>  
  </header>      
</template>
<script>
 export default {
   data() {
     return {
      customModal: false,
      logOutButton: true,
      imgSrc: require('../../assets/authotLogo.png'),
      customCSS: true,

     }
   },
    methods: {
      openCustomModal() {
        this.customModal = !this.customModal;
      },
      async logOut() {
        await this.$store.dispatch("sessionManager/logoutUser");
        this.$router.push("/login")
      },      
    },
    mounted() {
      if (localStorage.getItem("user_id") === null) {
        this.logOutButton = false;
      }
      // let customCSS = import("@/assets/stylesheet/customStyle/user" + localStorage.getItem("user_id") + ".scss"); 
      // customCSS.then((css) => {
      //   let style = document.createElement("style");
      //   style.innerHTML = css.default;
      //   document.head.appendChild(style);
      // });
      if(localStorage.getItem("user_id") != null) {
        this.customCSS = false
      }
    let noCache = document.createElement("meta");
    noCache.setAttribute("http-equiv", "pragma");
    noCache.setAttribute("content", "no-cache, no-store, must-revalidate");
    document.head.appendChild(noCache);
    }
 }
</script>