<template>
  <div>
    <CHeader/>
    <CContact/>
    <CFooter/>
  </div>
</template>

<script>
import CHeader from "../components/header/CHeader.vue"
import CContact from '../components/contact/CContact.vue'
import CFooter from '../components/footer/CFooter.vue'

export default {
  name: "VContact",
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  components: {
    CContact,
    CHeader,
    CFooter
  }
}
</script>
