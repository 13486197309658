import axios from "axios";
import AxiosSession from "../../services/sessionManager/sessionManager";

const BASE_URL = "https://api-premium.authot.com/";
// const BASE_URL = "http://localhost:3000/";

const sessionStore = {
  namespaced: true,
  state: () => ({
    auth_token: null,
    user: null,
    error: null,
  }),
  getters: {
    getAuthToken(state) {
      return state.auth_token;
    },
    getUserEmail(state) {
      return state.user?.email;
    },
    getUserID(state) {
      return state.user?.id;
    },
    isLoggedIn(state) {
      const loggedOut =
        state.auth_token == null || state.auth_token == JSON.stringify(null) || state.auth_token == undefined;
      return !loggedOut;
    },

  },
  mutations: {
    setUserInfo(state, data) {
      state.user = data.data.user;
      state.auth_token = data.headers.authorization;
      localStorage.setItem("auth_token", data.headers.authorization);
      localStorage.setItem("root_directory_id", data.data.root_directory);
      localStorage.setItem("user_id", data.data.user.id);
    },
    // setUserInfoFromToken(state, data) {
    //   state.user = data.data.user;
    //   state.auth_token = localStorage.getItem("auth_token");
    // },
    resetUserInfo(state) {
      console.log("resetUserInfo");
      state.user = {
        id: null,
        email: null,
      };
      state.auth_token = null;
      localStorage.removeItem("auth_token");
      localStorage.removeItem("root_directory_id");
      localStorage.removeItem("directory_id");
      localStorage.removeItem("user_id");
    },
  },
  actions: {
    registerUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${BASE_URL}users`, payload)
          .then((response) => {
            commit("setUserInfo", response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loginUser({ commit }, payload) {
      return AxiosSession.loginUser(payload)
        .then((response) => {
          commit("setUserInfo", response);
        })
        .catch((error) => {
          console.log(error);
          // if(error.response.status == 401){
          //   commit("resetUserInfo");
          // }          
        });
      // new Promise((resolve, reject) => {
      //   axios
      //     .post(`${BASE_URL}users/sign_in`, payload)
      //     .then((response) => {
      //       console.log(response)
      //       commit("setUserInfo", response);
      //       resolve(response);
      //     })
      //     .catch((error) => {
      //       reject(error);
      //     });
      // });
    },
    logoutUser({ commit }, payload) {
      return AxiosSession.logoutUser(payload)
        .then((response) => {
          commit("resetUserInfo", response);
        })
        .catch((error) => {
          console.log(error);
        });
      // new Promise((resolve, reject) => {
      //   return axios
      //     .delete(`${BASE_URL}users/sign_out`)
      //     .then(() => {
      //       commit("resetUserInfo");
      //       resolve();
      //     })
      //     .catch((error) => {
      //       reject(error);
      //     });
      // });
    },
    // loginUserWithToken({ commit }, payload) {
    //   console.log("HELLO HERE TEST : " + payload);
    //   return AxiosSession.loginWithToken(payload)
    //     .then((response) => {
    //       console.log(response);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
      // new Promise((resolve, reject) => {
      //   axios
      //     .get(`${BASE_URL}users/sign_in`, config)
      //     .then((response) => {
      //       console.log(response);
      //       // commit("setUserInfoFromToken", response);
      //       // resolve(response);
      //     })
      //     .catch((error) => {
      //       // reject(error);
      //     });
      // });
    //},
  }
}

export default sessionStore;


// const state = {
//   auth_token: null,
//   user: {
//     id: null,
//     email: null,
//   },
// };
// const getters = {
//   getAuthToken(state) {
//     return state.auth_token;
//   },
//   getUserEmail(state) {
//     return state.user?.email;
//   },
//   getUserID(state) {
//     return state.user?.id;
//   },
//   isLoggedIn(state) {
//     const loggedOut =
//       state.auth_token == null || state.auth_token == JSON.stringify(null);
//     return !loggedOut;
//   },
// };
// const actions = {
//   registerUser({ commit }, payload) {
//     return new Promise((resolve, reject) => {
//       axios
//         .post(`${BASE_URL}users`, payload)
//         .then((response) => {
//           commit("setUserInfo", response);
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   },
//   loginUser({ commit }, payload) {
//     new Promise((resolve, reject) => {
//       axios
//         .post(`${BASE_URL}users/sign_in`, payload)
//         .then((response) => {
//           commit("setUserInfo", response);
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   },
//   logoutUser({ commit }) {
//     const config = {
//       headers: {
//         authorization: state.auth_token,
//       },
//     };
//     new Promise((resolve, reject) => {
//       axios
//         .delete(`${BASE_URL}users/sign_out`, config)
//         .then(() => {
//           commit("resetUserInfo");
//           resolve();
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   },
//   loginUserWithToken({ commit }, payload) {
//     const config = {
//       headers: {
//         Authorization: payload.auth_token,
//       },
//     };
//     new Promise((resolve, reject) => {
//       axios
//         .get(`${BASE_URL}member-data`, config)
//         .then((response) => {
//           commit("setUserInfoFromToken", response);
//           resolve(response);
//         })
//         .catch((error) => {
//           reject(error);
//         });
//     });
//   },
// };
// const mutations = {
//   setUserInfo(state, data) {
//     state.user = data.data.user;
//     state.auth_token = data.headers.authorization;
//     axios.defaults.headers.common["Authorization"] = data.headers.authorization;
//     localStorage.setItem("auth_token", data.headers.authorization);
//   },
//   setUserInfoFromToken(state, data) {
//     state.user = data.data.user;
//     state.auth_token = localStorage.getItem("auth_token");
//   },
//   resetUserInfo(state) {
//     state.user = {
//       id: null,
//       email: null,
//     };
//     state.auth_token = null;
//     localStorage.removeItem("auth_token");
//     axios.defaults.headers.common["Authorization"] = null;
//   },
// };
// export default {
//   state,
//   getters,
//   actions,
//   mutations,
// };