import { createStore } from "vuex";
import sessionStore from "@/store/modules/session_manager";
//import folderStore from "@/store/modules/folder";
import directoriesStore from "@/store/modules/directory";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    sessionManager: sessionStore,
    //folderManager: folderStore,
    directoriesManager: directoriesStore,
  },
});