<template>
  <div class="contact-container">
    <div class="left">
      <h2>Contactez-nous</h2>
      <p>
        Vous souhaitez en savoir plus et essayer notre application ?
        Alors n’hésitez pas à nous contacter, nous serons ravis de répondre à vos besoins !
      </p>
  
      <form @onsubmit="formSubmit" class="contactForm">
        <label>Votre Email <span>*</span></label>
        <input type="email" id="email" class="ui input" placeholder="Ecrivez votre email ici"/>
        <label>Le Sujet <span>*</span></label>
        <input type="text" id="subject" class="ui input" placeholder="Ecrivez votre sujet ici"/>
        <label>Votre message <span>*</span></label>
        <input type="text" id="message" class="ui input message" placeholder="Ecrivez votre message ici"/>
        <button type="submit" class="ui button">Envoyer</button>
      </form>
    </div>
    
    <div class="contactImg">
      <div class="img" style="height: 60vh;"></div>
    </div>
  </div>
</template>

<script>
export default ({
  data() {
    return {
    }
  },
  methods: {
    formSubmit(){
      console.log("coucou")
    }
  },
})
</script>
