<template>
  <div>
    <div class="container">
      <div class="left-img">
        <div class="img" style="height: 60vh;"></div>
      </div>
      <div class="right-form">
      <!-- <div>
        <h3>Inscrivez-vous !</h3>
        <form @submit="onSignUp" class="sign-up-form form">
          <input class="sign-up-form-email" type="email" v-model="signUpEmail" placeholder="Votre email" />
          <br />
          <input type="password" class="sign-up-form-password" v-model="signUpPassword" placeholder="Votre mot de passe" />
          <br />
          <input type="submit" value="Sign up" class="sign-up-form-submit" />
        </form>
      </div> -->
        <h3>Connectez-vous !</h3>
        <form @submit="onLogin" class="login-form form">
          <input class="login-form-email" type="text" v-model="loginEmail" placeholder="Votre email" />
          <br />
          <input class="login-form-password" type="password" v-model="loginPassword" placeholder="Mot de passe" />
          <br />
          <input type="submit" value="Login" class="login-form-submit" />
        </form>
      </div>  
      
    </div>
    <Transition>
      <Toast v-if="displayToast" :message="message"></Toast> 
    </Transition>    
  </div>   
</template>

<script>
import "@/store/index.js";
import { mapActions, mapGetters } from "vuex";
import { displayToast } from "../../utils/functions";
import Toast from "../utils/UToast.vue";

export default {
  name: "CSessionManager",
  computed: {
    ...mapGetters(["getAuthToken", "getUserEmail", "getUserID", "isLoggedIn"]),
    getAuthToken(){
      return this.$store.getters["sessionManager/getAuthToken"];
    },
  },
  data() {
    return {
      signUpEmail: "",
      signUpPassword: "",
      loginEmail: "",
      loginPassword: "",
      displayToast: false,
      message: "",
    };
  },
  methods: {
    ...mapActions(["registerUser", "loginUser", "logoutUser"]),
    onSignUp(event) {
      event.preventDefault();
      let data = {
        user: {
          email: this.signUpEmail,
          password: this.signUpPassword,
        },
      };
      this.registerUser(data);
      this.resetData();
    },
    async onLogin(event) {
      event.preventDefault();
      let data = {
        user: {
          email: this.loginEmail,
          password: this.loginPassword,
        },
      };
      await this.$store.dispatch('sessionManager/loginUser', data)

      if(this.getAuthToken != undefined){
        this.$router.push("/")
      } else {
        //this.$router.push("/login")
        this.showToast("Information invalide")
      }
    },
    resetData() {
      this.signUpEmail = "";
      this.signUpPassword = "";
      this.loginEmail = "";
      this.loginPassword = "";
    },
    showToast(message) {
      this.displayToast = true;
      this.message = message;
      setTimeout(() => {
        this.displayToast = false;
      }, 5000);
    },
  },
  components: {
    Toast,
  },
  mounted() {
    if(localStorage.getItem("auth_token")){
      
    }
  },
}
</script>

<style scoped>

</style>