<template>
  <div class="pagination_container">
    <div class="pagination_select">
      <label>Afficher {{perPage}} éléments</label>
      <select v-model="perPage" class="pagination_select_selector" @change="emitRedrawList()">          
        <option>5</option>   
        <option>10</option>
        <option>15</option>
      </select>
    </div>
    <div class="pagination_navbar">
      <div class="pagination_navbar_pages_arrows">
        <button class="pagination_pages_rounded_btn" @click="customPageChange(1)"><i class="angle double left icon"></i></button>
        <button class="pagination_pages_rounded_btn" @click="getPreviousPage()"><i class="angle left icon"></i></button>
      </div>
      <div v-for="(page, i) in totalPages" :key="i">
        <button class="pagination_pages_rounded_btn" :class="{ 'active': i + x == current } " @click="customPageChange(i + x)">{{ i + x }}</button>
      </div>
      <div v-if="displayLast" class="lastPagesButton">
        <span>...</span>
        <button class="pagination_pages_rounded_btn" :class="{ 'active': pages == current }" @click="customPageChange(pages)">{{pages}}</button>
      </div>
      <div class="pagination_navbar_pages_arrows">
        <button class="pagination_pages_rounded_btn" @click="getNextPage()"><i class="angle right icon"></i></button>
        <button class="pagination_pages_rounded_btn" @click="customPageChange(pages)"><i class="angle double right icon"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['total', 'pageChanged', 'perPageChanged', 'dropdownId', 'typeOfItem'],
  data() {
    return {
      perPage: this.perPageChanged,
      current: this.pageChanged,
      pages: this.totalPages,
      type: this.typeOfItem,
      x: 1,
      displayLast: false
    }
  },
  computed: {
    // t() {
    //   return this.$store.getters['locales/t']
    // }
    totalPages(){
      if(this.total < 5) {
        return this.total;
      } else {
        this.displayLast = true;
        return 5;     
      }
    }
  },
  methods: {
    getPreviousPage() {
      if(this.current != 1) {
        this.customPageChange(this.current - 1);
        this.$emit('emitRedrawList', {type: this.typeOfItem, perPage: Number(this.perPage), current: this.current})
        if(this.current == 3) {
          this.x = 1;
        } else {
          this.x = this.x - 1;
        }
      } else { console.log('no more pages') }
    },
    getNextPage() {
      if(this.current != this.pages) {
        this.customPageChange(this.current + 1);
        this.$emit('emitRedrawList', {type: this.typeOfItem, perPage: Number(this.perPage), current: this.current})
        if(this.current > 3 && this.current != this.total) {
          this.x = this.current - 3;
        } else if(this.current == this.total-1){
          this.x = this.current - 4;
        }
      } else { console.log('no more pages') }
    },
    customPageChange(customCurrentPage) {
      this.current = customCurrentPage;
      this.$emit('emitRedrawList', {type: this.typeOfItem, perPage: Number(this.perPage), current: this.current})
      //this.pageChanged({currentPage: customCurrentPage});
    },
    customPerPageChange(customPerPage) {
      this.perPage = customPerPage;
      this.getTotalPages();
      this.perPageChanged({currentPerPage: customPerPage});
    },
    getTotalPages() {
      if(this.total > this.perPage) {
        this.pages = Math.ceil(this.total/this.perPage);
      } else {
        this.pages = 1;
      }
    },  
    emitRedrawList(){
      this.$emit('emitRedrawList', {type: this.typeOfItem, perPage: this.perPage, current: this.current});
    }, 
    customTab(current) {
      console.log("here", current)
      if(current < 3) {
        console.log("if")
        this.pages = [1, 2, 3, 4, 5]
      } else {
        this.pages.slice(current - 2, current + 2)
      }
    }
  },
  watch: {
    total: {
      deep: true,
      handler() {
        this.getTotalPages();
      }
    }
  },
  mounted() {
    
  }
}
</script>