import AxiosClient from "../axios-config";

export default {
  getDirectory(id, data) {
    AxiosClient.interceptors.request.use(
      config => {
        config.headers['Authorization'] = localStorage.getItem('auth_token');
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );
    if(id != null && id != undefined && id != "") { 
      if(data != null && data != undefined && data != "") {
        return AxiosClient.get("/directory/" + id , { params: {"[directory_page][per_page]": data.directory_page.per_page, "[directory_page][number_page]": data.directory_page.number_page, "[document_page][per_page]": data.document_page.per_page, "[document_page][number_page]": data.document_page.number_page}});
      } else {
        console.log("else")
        return AxiosClient.get("/directory/" + id);
      }

    } else {
      return AxiosClient.get("/directories");
    }
  },
  postDirectory(data) {
    return AxiosClient.post("/directory", data);
  },
  postDocument(data) {
    return AxiosClient.post("/document", data);
  },
  updateDirectory(id, data){
    const url= "/directory/" + id;
    return AxiosClient.put(url, data);
  },
  updateDocument(id, data) {
    const url = "/document/" + id;
    return AxiosClient.put(url, data);
  },
  deleteDirectory(id) {
    const url = "/directory/" + id;
    return AxiosClient.delete(url);
  },
  deleteDocument(id) {
    const url = "/document/" + id;
    return AxiosClient.delete(url);
  },
  getDocument(id){
    const url = "/document/" + id;
    return AxiosClient.get(url);
  },
  downloadDocument(id){
    const url = "/document/" + id + "/download";
    return AxiosClient.get(url);
  },
  getDirectories(user_id){
    return AxiosClient.get("/users/" + user_id + "/directories");
  }
}